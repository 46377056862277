<template>
    <modal
        class="search-memo"
        :adaptive="true"
        name="search-memo"
        width="970"
        height="90%"
        @before-close="beforeClose"
    >
        <div class="close-modal" @click="$modal.hide('search-memo')">
            <img src="@assets/img/icons/x.svg" alt="X" width="15px">
        </div>
        <div class="search-memo-container">
            <h2 class="title">
                Search Memo
            </h2>
            <div class="search-bar">
                <div class="search-input">
                    <img class="search-icon" src="@assets/img/icons/search.svg">
                    <input
                        type="text"
                        placeholder="Search for Memos you want to link"
                        @keyup="(event) => handleAsyncUserInput(event.target.value)"
                        @keyup.enter="(event) => handleUserInput(event.target.value)"
                    >
                </div>
            </div>
            <div v-if="!isUserSearching" class="search-categories">
                <div class="links">
                    <template v-for="(category, index) in searchCategories">
                        <button
                            :key="index"
                            class="link btn body-text-sb"
                            :class="{ 'is-active' : currentSearchCategory == category.name }"
                            @click="currentSearchCategory = category.name"
                        >
                            {{ category.label }}
                        </button>
                    </template>
                </div>
            </div>
            <div class="category-list">
                <memod-list
                    select-mode
                    :no-memos-found-message="noMemosFoundMessage"
                    :pagination-format="!isUserSearching"
                    :list-endpoint="isUserSearching ? `/search?q=${userInput}` : currentSearchCategoryData.endpoint"
                    @selected-memo="(memo) => selectedMemo = memo"
                />
            </div>
            <div class="add-memo">
                <button
                    :disabled="!Boolean(selectedMemo.id)"
                    class="btn btn-primary"
                    @click="$emit('selected-memo', selectedMemo);"
                >
                    Add Memo
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import debounce from "lodash/debounce";

export default {
    name: "SearchMemo",
    components: {
        MemodList: () => import(/* webpackChunkName: "memod-list" */ "@/components/organisms/memod-list.vue")
    },
    data() {
        return {
            userInput: "",
            selectedMemo: {},
            currentSearchCategory: "likes",
            searchCategories: [
                {
                    name: "likes",
                    label: "Likes",
                    endpoint: `/users/${this.$store.state.User.data.id}/feeds?type=liked`
                }
            ],
            searchResults: [],
            noMemosFoundMessage: {
                icon: require("@assets/img/icons/feed.svg"),
                title: "Sorry! No results found.",
                text: "Please try again."
            }
        };
    },
    computed: {
        isUserSearching() {
            return Boolean(this.userInput != "");
        },
        currentSearchCategoryData() {
            return this.searchCategories.find(category => category.name == this.currentSearchCategory);
        }
    },
    created() {
        this.handleAsyncUserInput = debounce(this.handleUserInput, 500);
    },
    methods: {
        handleUserInput(userInput) {
            this.userInput = encodeURIComponent(userInput);
        },
        beforeClose() {
            this.userInput = "";
            this.selectedMemo = {};
            this.searchResults = [];
        }
    }
}
</script>

<style lang="scss" scoped>
.search-memo {
    z-index: 99999;

    .close-modal {
        position: absolute;
        right: 40px;
        top: 40px;
        cursor: pointer;
        z-index: 3;

        @media(max-width: $sm) {
            right: 15px;
            top: 15px;

            img {
                width: 15px;
            }
        }
    }

    /deep/ .vm--modal {
        background-color: #0F0F10;
        border-radius: 10px;
        box-shadow: none;

        .search-memo-container {
            height: 100%;
            display: flex;
            flex-direction: column;

            .title {
                text-align: center;
                padding-top: 40px;
            }

            .search-bar {
                position: relative;
                width: 100%;
                padding: 40px 40px 0 40px;

                .search-icon {
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .search-input {
                    position: relative;
                    z-index: 3;

                    input {
                        @extend .body-text;
                        height: 38px;
                        width: 100%;
                        border-radius: 30px;
                        background-color: #242426;
                        box-shadow: none;
                        color: white;
                        padding-left: 45px;
                        padding-right: 15px;
                        transition: width .3s;
                        border: 0;

                        &:focus {
                            outline: 0;
                        }
                    }
                }
            }

            .search-categories {
                display: flex;
                justify-content: space-between;

                .links {
                    display: flex;
                    padding-top: 25px;
                    padding-bottom: 25px;
                    margin-left: auto;
                    margin-right: auto;

                    .link {
                        display: block;
                        margin-left: 65px;
                        cursor: pointer;
                        padding: 6px 20px;
                        border-radius: 100px;
                        color: white;
                        text-decoration: none;

                        @media(max-width: $lg) {
                            margin-left: 0;
                        }

                        &:first-child {
                            margin-left: 0;
                        }

                        &:focus {
                            box-shadow: none;
                        }

                        &.is-active {
                            background-color: $primary-color;
                        }
                    }
                }
            }

            .category-list {
                padding: 0 30px;
                flex: 1;
                overflow-y: auto;
                scrollbar-width: thin;
                scrollbar-color: $primary-color #2c2c2e;

                &::-webkit-scrollbar {
                    width: 6px;
                }

                &::-webkit-scrollbar-track {
                    background: #2c2c2e;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $primary-color;
                }
            }

            .add-memo {
                padding: 40px;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}
</style>
